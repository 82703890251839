import React from "react";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import ProductList from "../pages/ProductList";
import Login from "../pages/Login";
import CategoryPage from "./../pages/CategoryPage";
import VendorPage from "../pages/VendorPage";
import PurchaseRequestPage from "../pages/PurchaseRequestPage";
import PriorityPage from "./../pages/PriorityPage";
import VendorRFQ from "../pages/VendorRFQ";
import PurchaseQuotation from "../pages/PurchaseQuotation";
import ViewPurchaseQuotation from "../pages/ViewPurchaseQuotation";
import Test from "../pages/Test";
import PurchaseOrderList from "../pages/PurchaseOrderList";
import styles from "../styles/Login.module.css";
import PO_Payment from "../pages/PO_Payment";
import Swal from "sweetalert2";

function All_Routes() {
  // const [year, setYear] = useState(new Date());
  let year = new Date();
  let info_arr = year?.toString()?.split(" ");

  // let base_url = "https://beta-purchase.equinoxlab.com/api";
  let base_url = "https://purchase.equinoxlab.com/api";

  const msg_popUp = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Login msg_popUp={msg_popUp} />} />
        <Route
          path="/priority"
          element={<PriorityPage base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route
          path="/category"
          element={<CategoryPage base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route
          path="/products"
          element={<ProductList base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route
          path="/vendors"
          element={<VendorPage base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route
          path="/purchaseRequest"
          element={
            <PurchaseRequestPage base_url={base_url} msg_popUp={msg_popUp} />
          }
        />
        <Route
          path="/vendorRFQ/:vendorID/:PQ_ID/:PR_ID/:USER_ID?"
          element={<VendorRFQ base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route
          path="/purchaseQuotation"
          element={
            <PurchaseQuotation base_url={base_url} msg_popUp={msg_popUp} />
          }
        />
        <Route
          path="/viewPurchaseQuotation"
          element={
            <ViewPurchaseQuotation base_url={base_url} msg_popUp={msg_popUp} />
          }
        />
        <Route
          path="/purchaseOrderList"
          element={
            <PurchaseOrderList base_url={base_url} msg_popUp={msg_popUp} />
          }
        />
        <Route
          path="/po_payment"
          element={<PO_Payment base_url={base_url} msg_popUp={msg_popUp} />}
        />
        <Route path="/test" element={<Test base_url={base_url} />} />
      </Routes>

      <div id={styles.footer}>
        <footer class="footer" role="contentinfo">
          <div class="container">
            <div class="row justify-content-center text-center">
              <div class="col-md-7" style={{ marginTop: "0.7%" }}>
                <p class="copyright" style={{ color: "grey" }}>
                  &copy; Copyright {info_arr[3]} Equinox Labs.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default All_Routes;
