import React, { useEffect, useState } from "react";
import 'animate.css';
import styles from "../styles/VendorRFQStyles.module.css";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductPQList from "../Modals/ProductPQList";
import CreatePOFormModal from "../Modals/CreatePOFormModal";
import MiniDrawer from "./../components/MiniDrawer";
import dayjs from "dayjs";
import moment from "moment";
import EditRejectedPO from "../Modals/EditRejectedPO";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReactReadMoreReadLess from "react-read-more-read-less";
import CommentIcon from '@mui/icons-material/Comment';
import Swal from "sweetalert2";
import { Menu, } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Drawer, Modal, Tooltip, Typography } from "@mui/material";

function ViewPurchaseQuotation({ base_url, msg_popUp }) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");
  let role_id = storage.getItem("Role_id");
  let pr_id = storage.getItem("PR_ID");
  const navigate = useNavigate();

  const [viewPQData, setViewPQData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [productId, setProductId] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [productName, setProductName] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [PO_product_list, setPO_product_list] = useState([]);
  const [PO_vendor_details, setPO_vendor_details] = useState([]);
  const [offerAcceptedList, setOfferAcceptedList] = useState([]);
  const [first_vendor, setFirst_Vendor] = useState("");
  const [isDisplayEdit, setIsDisplayEdit] = useState(false);
  const [hubLocationList, setHubLocationList] = useState([]);

  // Purchase Quotation -for indivdual product
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCreatePO, setOpenCreatePO] = useState(false);
  const handleOpenCreatePO = () => setOpenCreatePO(true);
  const handleCloseCreatePO = () => setOpenCreatePO(false);

  const [openEditRejectedPo, setOpenEditRejectedPo] = useState(false);
  const handleOpenEditRejectedPo = () => setOpenEditRejectedPo(true);
  const handleCloseEditRejectedPo = () => setOpenEditRejectedPo(false);

  // ********************Used in Second method********************
  // const [greenClick, setgreenClick] = useState(false);
  // const [pqi_id, setPqi_id] = useState("");
  // const [selectVendor_id, setSelectVendor_id] = useState("");
  // const [pqi_id_list, setPqi_id_list] = useState([]);

  const [flag, setFlag] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [approvedCount, setApprovedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [partiallyRejectedCount, setpartiallyRejectedCount] = useState(0);
  const [approvedQuoteList, setApprovedQuoteList] = useState([]);
  const [rejectedPOList, setRejectedPOList] = useState([]);
  const [showBtn, setShowBtn] = useState(false)
  const [hubLocationId, setHubLocationId] = useState("")
  const [hubLocationAddress, setHubLocationAddress] = useState("")
  const [hubLocationName, setHubLocationName] = useState("")
  
  //   let updatedArray = [];
  const get_view_PQ_data = async (pr_id) => {
    let approved = 0;
    let rejected = 0;
    let inProgress = 0;
    let partiallyRejected = 0;
    let poRejected = 0;
    setIsLoading(true);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Quotation.svc/get_PURCHASE_QUOTATION_PRODUCT/${pr_id}`
      );
      let data = await res.json();
      setApprovedQuoteList(data?.approved_quots_list);
      setRejectedPOList(data?.Rejeccted_po_list);
      setViewPQData(data?.data);
      data?.data.forEach((ele) => {
        if (ele.QUOTATION_APPROVAL_STATUS == "QUOTATION APPROVED") {
          setIsApproved(true);
          approved = approved + 1;
        } else if (ele.QUOTATION_APPROVAL_STATUS == "QUOTATION REJECTED") {
          rejected++;
        } else if (ele.QUOTATION_APPROVAL_STATUS == "PARTIALLY REJECTED") {
          partiallyRejected++;
        } else if (ele.QUOTATION_APPROVAL_STATUS == "PO_REJECTED") {
          poRejected++;
        } else {
          inProgress = inProgress + 1;
        }
      });
      setIsLoading(false);
      setApprovedCount(approved);
      setRejectedCount(rejected);
      setInProgressCount(inProgress);
      setpartiallyRejectedCount(partiallyRejected)
    } catch (error) {
      msg_popUp("error", "Something went wrong!");
      console.log(error);
      setIsLoading(false);
    }
  };

  const get_product_list = async (pr_id, prod_id) => {
    setIsDetailsLoading(true);
    try {
      let res = await fetch(
        `${base_url}/Purchase_Quotation.svc/get_ALL_PURCHASE_QUOTATIONS_VENDOR_DETAILS/${pr_id}/${prod_id}`
        // `${base_url}/Purchase_Quotation.svc/get_ALL_PURCHASE_QUOTATIONS_VENDOR_DETAILS_REJECTED_PO/${pr_id}/${prod_id}`
      );
      let data = await res.json();
      setProductData(data?.data);
      setIsDetailsLoading(false);
    } catch (error) {
      msg_popUp("error", "Something went wrong!");
      console.log(error);
      setIsDetailsLoading(false);
    }
  };
  const get_product_list_rejected = async (pr_id, prod_id) => {
    setIsDetailsLoading(true);
    try {
      let res = await fetch(
        // `${base_url}/Purchase_Quotation.svc/get_ALL_PURCHASE_QUOTATIONS_VENDOR_DETAILS/${pr_id}/${prod_id}`
        `${base_url}/Purchase_Quotation.svc/get_ALL_PURCHASE_QUOTATIONS_VENDOR_DETAILS_REJECTED_PO/${pr_id}/${prod_id}`
      );
      let data = await res.json();
      setProductData(data?.data);
      setIsDetailsLoading(false);
    } catch (error) {
      msg_popUp("error", "Something went wrong!");
      console.log(error);
      setIsDetailsLoading(false);
    }
  };



  useEffect(() => {
    get_view_PQ_data(pr_id);
  }, []);

  // ************************2nd method of handle Click**************
  // const handleClickProduct = (data)=> {
  //   console.log(data)
  //   if(data.QUOTATION_APPROVAL_STATUS == "QUOTATION APPROVED"){
  //     if(selectedList.length == 0 ){
  //           selectedList.push({
  //               PQI_ID: data.PQI_ID,
  //               VENDOR_ID: data.VENDOR_ID
  //             })
  //             setPqi_id(data.PQI_ID)
  //             setSelectVendor_id(data.VENDOR_ID)
  //             setFlag(true)
  //           }
  //           else {
  //              for(let i=0;i<selectedList.length; i++){
  //               if(data.VENDOR_ID == selectedList[i].VENDOR_ID){
  //                 selectedList.push({
  //                   PQI_ID: data.PQI_ID,
  //                   VENDOR_ID: data.VENDOR_ID
  //                 })
  //                 if(data.PQI_ID == selectedList[i].PQI_ID){
  //                   setSelectVendor_id(data.VENDOR_ID)
  //                   setFlag(true)
  //                 }else{
  //                   setFlag(false)
  //                 }
  //               }
  //               else {
  //                 setgreenClick(false)
  //                 setFlag(false)
  //                 const Toast = Swal.mixin({
  //                   toast: true,
  //                   position: "top-end",
  //                   showConfirmButton: false,
  //                   timer: 1500,
  //                   timerProgressBar: true,
  //                   didOpen: (toast) => {
  //                     toast.addEventListener("mouseenter", Swal.stopTimer);
  //                     toast.addEventListener("mouseleave", Swal.resumeTimer);
  //                   },
  //                 });
  //                 Toast.fire({
  //                   icon: "error",
  //                   title: "please select same vendor product!",
  //                 });
  //               }
  //              }
  //           }
  //   }
  //     else {
  //           setProductId(data?.PRODUCT_ID);
  //           setProductName(data?.PRODUCT_NAME);
  //           handleOpen();
  //           get_product_list(data?.PRODUCT_ID);
  //         }

  // }

  // let hubLocationAddress = "";
  const getHubLocationList = async (hub_id) => {
    setHubLocationId(hub_id);
    try{
      let res = await fetch(
        `https://orion.equinoxlab.com/api/HubDetails.svc/Get_Hub_Address/all`
      );
      let data = await res.json();
      setHubLocationList(data?.data);
      data?.data?.map((ele) => {
        if(hub_id == ele.HUB_GUID){
          setHubLocationAddress(ele.HUB_ADDRESS);
          setHubLocationName(ele.HUB_NAME);
        }
      })
    }
    catch(error){
      console.log(error)
    }
  }

  let acceptedList = [];
  const handleCreatePO = async () => {
    let vendor_id = "";
    if (selectedList.length < 1) {
      msg_popUp("error", "Please select Approved Product to create P.O!");
    } else {
      selectedList.forEach((ele) => {
        // if(ele.QUOTATION_APPROVAL_STATUS == "QUOTATION APPROVED"){
        // setVendorId(ele.VENDOR_ID);
        setVendorId(ele.VENDOR_ID);
        vendor_id = ele.VENDOR_ID;
        acceptedList.push({
          PQI_ID: ele.PQI_ID,
        });
      });
      if (acceptedList.length > 0) {
        try {
          let res = await fetch(
            `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_DATA`,
            {
              method: "POST",
              headers: { "Content-Type": "Application/JSON" },
              body: JSON.stringify({
                VENDOR_ID: vendor_id,
                USER_ID: user_id,
                Purchase_Quotation_Details: acceptedList,
                PR_ID: pr_id,
              }),
            }
          );
          let data = await res.json();
          if (data?.response?.CODE == "200") {
            setPO_product_list(data?.Quotation_Data);
            setPO_vendor_details(data?.Vendor_Data);
            setHubLocationId(data?.Vendor_Data?.HUB_GUID);
            setOfferAcceptedList(acceptedList);
            // navigate("/createPO")
            getHubLocationList(data?.Vendor_Data?.HUB_GUID);
            handleOpenCreatePO();
          } else {
            handleCloseCreatePO();
            msg_popUp("error", "Something went wrong! Please try again");
          }
        } catch (error) {
          handleCloseCreatePO();
          console.log(error);
        }
      }
    }
  };

  let rejected_po_List = [];
  const handleCreatePoRejectedList = async (e) => {
    let vendor_id = "";
    // if (selectedList.length < 1) {
    //   msg_popUp("error", "Please select Rejected P.O to create P.O!");
    // } else {
      selectedList.forEach((ele) => {
        // if(ele.QUOTATION_APPROVAL_STATUS == "QUOTATION APPROVED"){
        // setVendorId(ele.VENDOR_ID);
        setVendorId(ele.VENDOR_ID);
        vendor_id = ele.VENDOR_ID;
        rejected_po_List.push({
          PQI_ID: ele.PQI_ID,
        });
      });
      if (rejected_po_List.length > 0) {
        try {
          let res = await fetch(
            `${base_url}/Purchase_Order.svc/get_PURCHASE_ORDER_DATA`,
            {
              method: "POST",
              headers: { "Content-Type": "Application/JSON" },
              body: JSON.stringify({
                VENDOR_ID: vendor_id,
                USER_ID: user_id,
                Purchase_Quotation_Details: rejected_po_List,
                PR_ID: pr_id,
              }),
            }
          );
          let data = await res.json();
          if (data?.response?.CODE == "200") {
            setPO_product_list(data?.Quotation_Data);
            setPO_vendor_details(data?.Vendor_Data);
            setHubLocationId(data?.Vendor_Data?.HUB_GUID);
            setOfferAcceptedList(rejected_po_List);
            // navigate("/createPO")
            getHubLocationList(data?.Vendor_Data?.HUB_GUID);
            handleOpenCreatePO();
          } else {
            handleCloseCreatePO();
            msg_popUp("error", "Something went wrong! Please try again");
          }
        } catch (error) {
          handleCloseCreatePO();
          console.log(error);
        }
      } else {
        msg_popUp("error", "Something went wrong! Please try again");
      }
  };

  const check_pq_id = (ele) => {
    for (let i = 0; i < selectedList.length; i++) {
      if (selectedList[i]?.PQI_ID == ele?.PQI_ID) {
        return true;
      }
    }
    return false;
  };

  const handleClicked = (ele) => {
    //  let temp_arr = []
    if (ele.QUOTATION_APPROVAL_STATUS == "QUOTATION APPROVED") {
      // temp_arr.push({
      //   "PQI_ID": ele?.PQI_ID,
      //   "VENDOR_ID": ele?.VENDOR_ID
      //  })

      if (selectedList?.length > 0) {
        if (first_vendor == ele?.VENDOR_ID && !check_pq_id(ele)) {
          setSelectedList([
            ...selectedList,
            {
              PQI_ID: ele?.PQI_ID,
              VENDOR_ID: ele?.VENDOR_ID,
            },
          ]);
        } else {
          let x = 1;
        }
      } else {
        setSelectedList([
          ...selectedList,
          {
            PQI_ID: ele?.PQI_ID,
            VENDOR_ID: ele?.VENDOR_ID,
          },
        ]);
        setFirst_Vendor(ele?.VENDOR_ID);
      }
    } 
    else if (ele.QUOTATION_APPROVAL_STATUS == "QUOTATION REJECTED"){
      const x ="0";
    }
    else{
      setProductId(ele?.PRODUCT_ID);
      setProductName(ele?.PRODUCT_NAME);
      setSelectedList([]);
      handleOpen();
      get_product_list(pr_id, ele?.PRODUCT_ID);
    }
  };

  const handleClickPoRejected = (ele) => {
     if (ele.QUOTATION_APPROVAL_STATUS == "PO_REJECTED") {
      setShowBtn(true)
      if (selectedList?.length > 0) {
        if (first_vendor == ele?.VENDOR_ID && !check_pq_id(ele)) {
          setSelectedList([
            ...selectedList,
            {
              PQI_ID: ele?.PQI_ID,
              VENDOR_ID: ele?.VENDOR_ID,
            },
          ]);
        } else {
          let x = 1;
        }
      } else {
        setSelectedList([
          ...selectedList,
          {
            PQI_ID: ele?.PQI_ID,
            VENDOR_ID: ele?.VENDOR_ID,
          },
        ]);
        setFirst_Vendor(ele?.VENDOR_ID);
      }
    } 
    else{
      setProductId(ele?.PRODUCT_ID);
      setProductName(ele?.PRODUCT_NAME);
      setSelectedList([]);
      handleOpenEditRejectedPo();
      // get_product_list(pr_id, ele?.PRODUCT_ID);
      get_product_list_rejected(pr_id, ele?.PRODUCT_ID);
    }
  }

  const handleEditPORejected = (ele) => {
    setProductId(ele?.PRODUCT_ID);
      setProductName(ele?.PRODUCT_NAME);
      setSelectedList([]);
      handleOpenEditRejectedPo();
      // get_product_list(pr_id, ele?.PRODUCT_ID);
      get_product_list_rejected(pr_id, ele?.PRODUCT_ID);
  }

  const handleBorder = (ele) => {
    if (selectedList.length > 0) {
      for (let i = 0; i < selectedList.length; i++) {
        if (selectedList[i]?.VENDOR_ID == ele?.VENDOR_ID) {
          if (selectedList[i]?.PQI_ID == ele?.PQI_ID) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return (
    <>
      <div
        className="container-fluid"
        // style={{ marginLeft: "-3em", marginTop: "-4em" }}
      >
        <MiniDrawer header_name={"Indent Wise Purchase Quotation"} />

        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          <div className="col-2" style={{ display: "flex" }}>
            <button
              type="button"
              id={styles.bckbtn}
              title="Back"
              class="btn"
              onClick={() => navigate("/purchaseQuotation")}
            >
              <ArrowBackIcon />
            </button>
          </div>
          <div
            className="col-10"
            style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <p
              style={{
                // width: "16%",
                marginTop:"8px",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span className={styles.approved}>
                {approvedQuoteList.length + approvedCount}
              </span>
              <span>Quotation Approved</span>
            </p>
            <p
              style={{
                // width: "16%",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span className={styles.rejected}>{rejectedCount}</span>
              <span>Quotation Rejected</span>
            </p>
            <p
              style={{
                // width: "16%",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span className={styles.inprogress}>{inProgressCount}</span>
              <span>Inprogress</span>
            </p>
            <p
              style={{
                // width: "16%",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span className={styles.partiallyRejected}>{partiallyRejectedCount}</span>
              <span>Partially Rejected</span>
            </p>
            <p
              style={{
                // width: "16%",
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <span className={styles.poRejected}>{rejectedPOList?.length}</span>
              <span>PO Rejected</span>
            </p>
          </div>
        </div>
        {isLoading ? (
          <div>
            <Loader />
          </div>
        ) : (
          <>
            <div className="row" style={{ marginBottom: "10vh" }}>
              <div
                className="col-12"
                style={{ display: "flex", marginTop: "4vh" }}
              >
                {/* <h4 className="tableName">Indent Wise Purchase Quotation</h4> */}
              </div>

              <div
                className="row"
                style={{ maxHeight: "70vh", overflowY: viewPQData?.length > 0 ? "auto" : "hidden" }}
              >
                <div style={{ textAlign: "left" }}>
                    <h4 style={{ fontWeight: "700", marginTop:"10px" }}>
                      Quotations
                    </h4>
                  </div>
                {viewPQData?.length > 0 ? (
                  <>
                    {viewPQData.map((ele) => (
                      <>
                        <div
                          className={`col-md-2 ${styles.quotationcard}`}
                          id={`divs-${ele.PQI_ID}`}
                          // onClick={() => handleClickProduct(ele)}
                          onClick={() => handleClicked(ele)}
                          style={{
                            backgroundColor:
                              ele.QUOTATION_APPROVAL_STATUS ==
                              "QUOTATION APPROVED"
                                ? "#92d087"
                                : ele.QUOTATION_APPROVAL_STATUS == "INPROGRESS"
                                ? "white"
                                : 
                                ele.QUOTATION_APPROVAL_STATUS == "PARTIALLY REJECTED" 
                                ? "#FF7F3E"
                                : ele.QUOTATION_APPROVAL_STATUS == "PO REJECTED" 
                                ? "#f86a6a"
                                : 
                                "rgb(237 69 69)",
                            color:
                              ele.QUOTATION_APPROVAL_STATUS == "INPROGRESS"
                                ? "black"
                                : "white",
                            cursor:
                              ele.QUOTATION_APPROVAL_STATUS ==
                              "QUOTATION REJECTED"
                                ? "not-allowed"
                                : "default",
                            // pointerEvents:
                            //   ele.QUOTATION_APPROVAL_STATUS ==
                            //   "QUOTATION REJECTED"
                            //     ? "none"
                            //     : "all",
                            border: handleBorder(ele)
                              ? "2px dashed #205e15"
                              : "none",
                          }}
                        >
                          {ele.QUOTATION_APPROVAL_STATUS ==
                          "QUOTATION APPROVED" ? (
                            <p style={{ fontSize: "16px", fontWeight: "700" }}>
                              {ele.VENDOR_NAME}
                            </p>
                          ) : (
                            <p style={{ fontSize: "45px", fontWeight: "700" }}>
                              {ele.PQ_COUNT}
                            </p>
                          )}
                          <p
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              maxHeight: "12vh",
                            }}
                          >
                            {ele.PRODUCT_NAME}
                          </p>
                        </div>
                      </>
                    ))}
                  </>
                ) : (
                  <div style={{ position: "relative", top: "0vh" }}>
                    <h6 style={{ fontWeight: "bold" }}>Data Not Available</h6>
                  </div>
                )}

                {isApproved && first_vendor ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "6vh",
                        marginBottom: "3vh",
                      }}
                    >
                      <button
                        className="btn btn-primary btn-sm"
                        // onClick={()=>handleCreatePO()}
                        onClick={handleCreatePO}
                      >
                        Create P.O
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {approvedQuoteList?.length > 0 ? (
                <div style={{ marginTop: "5vh", borderTop:"1px dashed #757575" }}>
                  <div style={{ textAlign: "left" }}>
                    <h4 style={{ fontWeight: "700", marginTop:"10px" }}>
                      PO Created List
                    </h4>
                  </div>
                  <div className="row">
                    {approvedQuoteList.map((ele) => (
                      <div
                        className={`col-md-2 ${styles.quotationcard}`}
                        id={`divs-${ele.PQI_ID}`}
                        style={{
                          cursor: "auto",
                          backgroundColor: "#92d087",
                          color: "white",
                        }}
                      >
                        <p style={{ fontSize: "16px", fontWeight: "700" }}>
                          {ele.VENDOR_NAME}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            maxHeight: "12vh",
                          }}
                        >
                          {ele.PRODUCT_NAME}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

{/* {rejectedPOList.length > 0 ? ( */}
                <div style={{ marginTop: "5vh", borderTop:"1px dashed #757575", paddingLeft:"0px" }}
                  // onClick={() => handleClicked(ele)}
                  
                >
                  <div style={{ textAlign: "left", paddingLeft:"10px", }}>
                    <h4 style={{ fontWeight: "700", marginTop:"10px" }}>
                      PO Rejected List
                    </h4>
                  </div>
                  <div className="row">
                    
                    {rejectedPOList?.length > 0 ?
                    rejectedPOList?.map((ele) => (
                      <>
                      <div style={{width:"18vw"}}>
                       <div
                        className={`${styles.quotationcard} ${styles.forRejected}`}
                        id={`divs-${ele.PQI_ID}`}
                        style={{
                          paddingTop:"0px",
                          marginBottom:"5px",
                          cursor: "pointer",
                          width:"100%",
                          backgroundColor: "#ed4545",
                          color: "white",
                          border: handleBorder(ele)
                              ? "2px dashed #205e15"
                              : "none",
                        }}
                        // onClick={() => handleClickPoRejected(ele)}
                      >
                        
                        <p style={{ fontSize: "16px", fontWeight: "700" }}>
                          {ele.VENDOR_NAME}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            maxHeight: "12vh",
                          }}
                        >
                          {ele.PRODUCT_NAME}
                        </p>
                        
                      </div>
                      <div className={`${styles.rejectedButtons} ${styles.selectEdit}`}>
                        {/* ***************Edit************ */}
                        <Tooltip title="Edit" placement="top" >
                          <button 
                            className= "btn btn-danger btn-sm"
                            onClick={() => handleEditPORejected(ele)}
                            style={{
                              paddingRight:"12px",width: "35px", height:"30px", display:"flex", 
                              justifyContent:"center", alignItems:'center',
                            }}
                          > 
                          <ModeEditIcon style={{marginLeft:"5px", fontSize:"16px"}} />
                          </button>
                        </Tooltip>
                        {/* ***************Select************ */}
                        <Tooltip title="Select" placement="top" >
                          <button 
                            className= "btn btn-success btn-sm"
                            onClick={() => handleClickPoRejected(ele)}
                            style={{
                              paddingRight:"12px",width: "35px", height:"30px", display:"flex", 
                              justifyContent:"center", alignItems:'center',
                            }}
                          > 
                          <CheckCircleOutlineIcon style={{marginLeft:"5px", fontSize:"16px"}} />
                          </button>
                        </Tooltip>
                        {/* ***************Comment************ */}
                        <Tooltip title={ele.PO_REJECTED_REMARK} placement="top"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: "#e8e5e5",
                                color: "black",
                                maxHeight:"150px", 
                                overflowY:"auto",
                                fontSize:"12px",
                                padding:"8px",
                              }
                            }
                          }}
                        >
                          <div 
                            style={{
                              height: "30px",
                              width: "35px",
                              backgroundColor: "grey",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}  
                          >
                            <CommentIcon style={{fontSize:"16px", color:"white"}} />
                            </div>
                        </Tooltip>

                          </div>
                        

                          


                      </div>

                      
                     </>
                    ))
                    :
                    <>
                      <div>Data Not Available</div>
                    </>
                    }
                  </div>
                </div>
              {/* // ) : (
              //   <></>
              // )} */}


                  


            </div>
            <ProductPQList
              base_url={base_url}
              user_id={user_id}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
              productData={productData}
              isDetailsLoading={isDetailsLoading}
              productId={productId}
              isApproved={isApproved}
              setIsApproved={setIsApproved}
              productName={productName}
              get_product_list={get_product_list}
              msg_popUp={msg_popUp}
              isDisplayEdit={isDisplayEdit}
              setIsDisplayEdit={setIsDisplayEdit}
            />

            <EditRejectedPO
              base_url={base_url}
              user_id={user_id}
              open={openEditRejectedPo}
              handleOpen={handleOpenEditRejectedPo}
              handleClose={handleCloseEditRejectedPo}
              productData={productData}
              isDetailsLoading={isDetailsLoading}
              productId={productId}
              isApproved={isApproved}
              setIsApproved={setIsApproved}
              productName={productName}
              get_product_list={get_product_list_rejected}
              msg_popUp={msg_popUp}
              isDisplayEdit={isDisplayEdit}
              setIsDisplayEdit={setIsDisplayEdit}
            />

            <CreatePOFormModal
              base_url={base_url}
              user_id={user_id}
              open={openCreatePO}
              handleOpen={handleOpenCreatePO}
              handleClose={handleCloseCreatePO}
              PO_product_list={PO_product_list}
              PO_vendor_details={PO_vendor_details}
              vendor_id={vendorId}
              acceptedList={acceptedList}
              offerAcceptedList={offerAcceptedList}
              flag={flag}
              setFlag={setFlag}
              msg_popUp={msg_popUp}
              hubLocationList={hubLocationList}
              hubLocationId={hubLocationId}
              hubLocationAddress={hubLocationAddress}
            setHubLocationAddress={setHubLocationAddress}
              hubLocationName={hubLocationName}
              setHubLocationName={setHubLocationName}
            />
          </>
        )}
                {
                  showBtn ?
                  <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "6vh",
                      marginBottom: "8vh",
                    }}
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      // onClick={()=>handleCreatePO()}
                      onClick={(e)=>handleCreatePoRejectedList(e)}
                    >
                      Create P.O
                    </button>
                  </div>
                </>
                :
                <></>
                }
                 
      </div>
    </>
  );
}

export default ViewPurchaseQuotation;
